export const teamMembers = [{
        id: 1,
        name: 'Анатолий Осипов',
        title: 'Гендиректор, Simbios Agency',
        text: '',
        image: 'team/1.jpg',
        socials: {
            twitter: '',
            discord: '',
            github: '',
            email: '',
            linkedin: '',
        }
    },
    {
        id: 1,
        name: 'Попов Айтал',
        title: 'Арт Директор, Simbios Labs',
        text: '',
        image: 'team/ayt.jpg',
        socials: {
            twitter: '',
            discord: '',
            github: '',
            email: '',
            linkedin: '',
        }
    },
    {
        id: 1,
        name: 'Васильев Андрей',
        title: 'Back-End Developer, Simbios Labs',
        text: '',
        image: 'team/5.jpg',
        socials: {
            twitter: '',
            discord: '',
            github: '',
            email: '',
            linkedin: '',
        }
    },
    {
        id: 1,
        name: 'Кычкин Андрей',
        title: 'СТО, Developer, Simbios Labs',
        text: '',
        image: 'team/4.jpg',
        socials: {
            twitter: '',
            discord: '',
            github: '',
            email: '',
            linkedin: '',
        }
    },
    // {
    //     id : 1,
    //     name : 'Васильев Андрей',
    //     title: '',
    //     text: '',
    //     image: 'team/5.jpg',
    //     socials: {
    //         twitter : '',
    //         discord : '',
    //         github : '',
    //         email : '',
    //         linkedin : '',
    //     }
    // },
    // {
    //     id : 1,
    //     name : 'Матвеева Алина',
    //     title: '',
    //     text: '',
    //     image: 'team/12.jpg',
    //     socials: {
    //         twitter : '',
    //         discord : '',
    //         github : '',
    //         email : '',
    //         linkedin : '',
    //     }
    // },
    // {
    //     id : 1,
    //     name : 'Андреев Айтал',
    //     title: '',
    //     text: '',
    //     image: 'team/6.jpg',
    //     socials: {
    //         twitter : '',
    //         discord : '',
    //         github : '',
    //         email : '',
    //         linkedin : '',
    //     }
    // },
    // {
    //     id : 1,
    //     name : 'Кугунуров Андрей',
    //     title: '',
    //     text: '',
    //     image: 'team/11.jpg',
    //     socials: {
    //         twitter : '',
    //         discord : '',
    //         github : '',
    //         email : '',
    //         linkedin : '',
    //     }
    // },
    // {
    //     id : 1,
    //     name : 'Ефимов Стёпа',
    //     title: '',
    //     text: '',
    //     image: 'team/9.jpg',
    //     socials: {
    //         twitter : '',
    //         discord : '',
    //         github : '',
    //         email : '',
    //         linkedin : '',
    //     }
    // },
    // {
    //     id : 1,
    //     name : 'Крылов Николай',
    //     title: '',
    //     text: '',
    //     image: 'team/7.jpg',
    //     socials: {
    //         twitter : '',
    //         discord : '',
    //         github : '',
    //         email : '',
    //         linkedin : '',
    //     }
    // },
    // {
    //     id : 1,
    //     name : 'Степанов Александр',
    //     title: '',
    //     text: '',
    //     image: 'team/8.jpg',
    //     socials: {
    //         twitter : '',
    //         discord : '',
    //         github : '',
    //         email : '',
    //         linkedin : '',
    //     }
    // },
]


// Кычкин Андрей 
// Ефимова Валерия 
// Семёнова Августина 
// Андреев Айтал
// Попов Айтал
// Васильев Андрей 
// Ефимов Стёпа 
// Матвеева Алина 
// Степанов Александр 
// Кугунуров Андрей
// Крылов Николай